import React, { useState } from 'react';
import { Table, Button, Input, InputNumber, Popover } from 'antd';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { Roles } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import AppTable from '../../components/tables/AppTable/AppTable';

const data = [
	{
		id: 1,
		email: 'john@gmci.com',
		count_used_tips: 900,
		countFreeTips: 100,
		count_rest_paid_tips: 5000,
		tariff: 'basic',
	},
	{
		id: 2,
		email: 'jim@gmci.com',
		count_used_tips: 10000,
		countFreeTips: 0,
		count_rest_paid_tips: 5000,
		tariff: 'advanced',
	},
];

const columns = [
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		ellipsis: true,
	},
	{
		title: 'Tariff',
		dataIndex: 'tariff',
		key: 'tariff',
	},
	{
		title: 'Used tips',
		dataIndex: 'count_used_tips',
		key: 'count_used_tips',
		width: 150,
		render: (count_used_tips) => {
			return count_used_tips;
		},
	},
	{
		title: 'Free tips',
		dataIndex: 'countFreeTips',
		key: 'countFreeTips',
		ellipsis: true,
	},
	{
		title: 'Rest paid tips',
		dataIndex: 'count_rest_paid_tips',
		key: 'count_rest_paid_tips',
		ellipsis: true,
	},
];

interface Props {}

const UsersDebt = (props: Props) => {
	const [selected, setSelected] = useState([]);
	const rowSelection = {
		selected,
		onChange: (keys) => setSelected(keys),
	};

	return (
		<>
			<Helmet>
				<title>Debts</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Debts</h1>

				<Input.Group style={{ marginBottom: 16 }}>
					<InputNumber
						min={1}
						style={{ width: 170, marginRight: 20 }}
						placeholder="Used tips"
						disabled={!selected.length}
					/>
					<Button type="primary" disabled={!selected.length}>
						Set count
					</Button>
				</Input.Group>

				<AppTable pagination={false} columns={columns} dataSource={data} rowSelection={rowSelection} />
			</div>
		</>
	);
};

export default withPrivateRoute(UsersDebt, [Roles.admin]);
